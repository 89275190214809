a {
  text-decoration: none;
}
.news-card {
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.news-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.news-image {
  height: 100%;
  object-fit: cover;
}

.news-title {
  font-weight: bold;
  font-size: 1.5rem;
}

.news-description {
  color: #555;
  margin: 15px 0;
}

.similar-articles {
  padding-left: 20px;
  list-style-type: disc;
}

.similar-link {
  color: #007bff;
  text-decoration: none;
}

.similar-link:hover {
  text-decoration: underline;
  color: #0056b3;
}

/*------Footer---------*/
footer ul {
  list-style: none;
  text-decoration: none;
}
.footer-items li:first-child {
  border-top: 1px solid rgb(80, 95, 121);
  margin-top: 1rem;
  padding: 1rem 0px;
}
.footer-items li {
  border-bottom: 1px solid rgb(80, 95, 121);
  padding: 0px 0px 1rem;
  margin: 0px 0px 1rem;
}
.c-footer-links a {
  align-items: center;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  display: flex;
  height: 24px;
  width: 24px;
  margin: 0px !important;
}
.c-footer-links a svg {
  height: 60%;
  transition: all 0.15s ease 0s;
  width: 60%;
  margin: auto !important;
}
@media only screen and (min-width: 1024px) {
  .footer-items li:first-child {
    border-top: none;
    margin-top: 0rem;
    padding: 0rem 0px;
  }
  .footer-items li {
    border: none;
    padding: 0px;
    margin: 0px;
    width: 25%;
  }
  .footer-items li:hover {
    background-color: #ececee;
  }
  .width-lg-100 {
    width: 100%;
  }
  .footer-items {
    padding: 0 1rem;
  }
  .border-footer {
    border-top: 1px solid rgb(80, 95, 121);
    width: 98%;
    margin: 0px auto;
  }
}
/* why ayg section */
.c-ayg-effect-1 {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}
.c-ayg-effect-1 .c-ayg-effect-img {
  font-size: 0;
}
.c-ayg-effect-1 .c-ayg-effect-img img {
  width: 100%;
  height: 280px;
}
.c-ayg-effect-1 .c-ayg-effect-text {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 30px;
  top: calc(100% - 60px);
  left: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}
.c-ayg-effect-1:hover .c-ayg-effect-text {
  top: 0;
}
.c-ayg-effect-1 .c-ayg-effect-text h2 {
  height: 45px;
  color: #000000;
  font-size: 25px;
  margin: -15px 0 0 0;
  transition: 0.5s;
}
.c-ayg-effect-1:hover .c-ayg-effect-text h2 {
  margin: 0;
}
.c-ayg-effect-1 .c-ayg-effect-text p {
  color: #000000;
  font-size: 16px;
  margin: 0 0 20px 0;
}
.c-ayg-effect-1 .c-ayg-effect-btn .c-ayg-btn {
  display: inline-block;
  height: 35px;
  padding: 7px 15px;
  color: #000000;
  background: #ffffff;
  text-decoration: none;
}
/* ...................................... */
